export default function LinkSlot({ url, children }) {
  return (
    <a
      href={url}
      target="_blank"
      className="underline"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
