import React from "react";

export default function CloseButton({ close }) {
  return (
    <button
      type="button"
      className="ml-auto flex flex-col justify-center items-center rounded-full w-12 h-12 sm:text-base ring-1 ring-inset ring-black bg-black group hover:bg-white"
      onClick={close}
    >
      <span className="sr-only">Close panel</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="group-hover:stroke-black"
      >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
  );
}
