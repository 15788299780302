import React from "react";

import { useStoryblokProxy } from "../hooks/useStoryblokProxy";
import ArticleCard from "./ArticleCard";

export default function RelatedWorkItem({ relatedWorkItemId }) {
  const { isLoading, stories, rels } = useStoryblokProxy({
    starts_with: "work",
    by_uuids: relatedWorkItemId,
    resolve_relations: ["Case.categories"],
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : null;

  return (
    <ArticleCard
      article={story?.content}
      slug={`work/${story?.slug}`}
      categories={rels}
      relatedItem
    />
  );
}
