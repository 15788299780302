import React, { useState, useEffect, useRef } from "react";

function AnimatedCounter({ amount, title }) {
  const counterContainer = useRef(null);
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    let intervalId;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && currentNumber < amount) {
            const updateInterval = 10;
            const totalDuration = 2000;
            const totalIncrements = totalDuration / updateInterval;
            const incrementAmount = Math.ceil(amount / totalIncrements);

            intervalId = setInterval(() => {
              setCurrentNumber((prevNumber) => {
                const nextNumber = prevNumber + incrementAmount;
                if (nextNumber >= amount) {
                  clearInterval(intervalId);
                  return amount;
                }
                return nextNumber;
              });
            }, updateInterval);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (counterContainer.current) {
      observer.observe(counterContainer.current);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      observer.disconnect();
    };
  }, [amount, currentNumber]);

  return (
    <div ref={counterContainer}>
      <div className="h-20 w-full flex justify-center">
        <div className="text-4xl md:text-6xl mb-4">
          {currentNumber + 1 <= amount ? currentNumber + 1 : amount}
        </div>
      </div>
      <span className="uppercase">{title}</span>
    </div>
  );
}

export default AnimatedCounter;
