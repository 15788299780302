import ReactGA from "react-ga4";

/**
 * Tracks a button click event using ReactGA.
 *
 * @param {string} buttonName - The name of the button.
 */
const trackButtonClick = (buttonName) => {
  ReactGA.event({
    category: 'Button',
    action: 'Click',
    label: buttonName,
  });
};

export default trackButtonClick;
