import React from "react";

export default function ErrorFallback({ renderReason, renderMessage }) {
  return (
    <div
      className="lg:max-w-[1440px] px-7 md:m-auto relative z-10"
      data-testid="error-fallback-container"
    >
      <div className="flex flex-col md:flex-row w-full h-[calc(100vh-171px)] md:h-[calc(100vh-123px)] justify-center md:justify-evenly items-center gap-y-12 gap-x-8 m-auto z-10 text-center min-h-screen">
        <div className="md:w-1/2">
          <h1 className="uppercase text-6xl md:text-7xl lg:text-8xl xl:text-8xl text-white font-extrabold ">
            {renderReason()}
          </h1>
          <div className="bg-white inline-flex px-2.5  md:pr-16 mt-3 mb-9 rounded-[100px]">
            <span className="md:text-lg uppercase font-digital pl-1.5">
              {renderMessage()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
