import React from "react";

import { contentType } from "../Constants";
import RichText from "../components/Storyblok/RichText";
import Indent from "../components/Storyblok/Indent";
import Images from "../components/Storyblok/Images";
import Deliverables from "../components/Storyblok/Deliverables";
import Quote from "../components/Storyblok/Quote";
import Video from "../components/Storyblok/Video";
import Table from "../components/Storyblok/Table";
import WhatWeDoOverview from "../components/Storyblok/WhatWeDoOverview";

export const PageBody = ({ className, blok }) => {
  return (
    <div className={className}>
      {blok?.body?.map((section) => {
        return (
          <React.Fragment key={section._uid}>
            {contentType.richText === section.component && (
              <RichText document={section.richText} />
            )}
            {contentType.indent === section.component && (
              <Indent blok={section} />
            )}
            {contentType.images === section.component && (
              <Images blok={section} />
            )}
            {contentType.deliverables === section.component && (
              <Deliverables blok={section} />
            )}
            {contentType.quote === section.component && (
              <Quote blok={section} />
            )}
            {contentType.video === section.component && (
              <Video blok={section} />
            )}
            {contentType.table === section.component && (
              <Table blok={section} />
            )}
            {contentType.whatWeDoOverview === section.component && (
              <WhatWeDoOverview />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
