export default function Table({ blok }) {
  return (
    <div className="w-full">
      <div className="flex flex-col justify-center lg:flex-row">
        <div className="overflow-x-scroll text-lg">
          {blok.table && (
            <table>
              <thead>
                <tr>
                  {blok.table.thead.map((head) => (
                    <th key={head._uid} className="align-top text-white">
                      {head.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {blok.table.tbody.map((row) => (
                  <tr key={row._uid}>
                    {row.body.map((cell) => (
                      <td key={cell._uid}>{cell.value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}
