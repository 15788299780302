export default function Video({ blok }) {
  const embedUrl = `https://www.youtube-nocookie.com/embed/${getVideoId(
    blok?.video
  )}`;

  function getVideoId(videoUrl) {
    const fullUrlMatch = videoUrl.match(
      /(?:https:\/\/www\.youtube\.com\/watch\?v=|https:\/\/youtu.be\/)([\w-]+)/
    );
    if (fullUrlMatch) {
      return fullUrlMatch[1];
    }

    // If the URL is not recognized, return an empty string
    return "";
  }

  if (embedUrl) {
    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        src={embedUrl}
        frameBorder="0"
        allowFullScreen
        className="my-8 w-full aspect-video"
      />
    );
  }
}
