import ReactGA from "react-ga4";
import * as CookieConsent from "vanilla-cookieconsent"

const handleAnalyticsConsent = () => {
  const analyticsID = process.env.REACT_APP_GA_TRACKING_ID;
  const disableKey = `ga-disable-${analyticsID}`;
  
  if (CookieConsent.acceptedCategory("analytics")) {
    window[disableKey] = false;
    if (!window.ReactGAInitialized) {
      ReactGA.initialize(analyticsID);
      window.ReactGAInitialized = true;
    }
  } else {
    window[disableKey] = true;
  }
};

export default handleAnalyticsConsent;
