export default function Indent({ blok }) {
  return (
    <div className="my-8 indent">
      <h3 className="font-bold text-white text-2xl">{blok?.title}</h3>
      <div
        className="indent-border border-l-4 pl-4 mt-4 border-primary text-white md:text-lg"
        dangerouslySetInnerHTML={{
          __html: blok?.indent,
        }}
      />
    </div>
  );
}
