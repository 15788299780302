import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useStoryblokProxy } from "../hooks/useStoryblokProxy";
import { PageBody } from "../storyblok/PageBody";
import { defaultPageTitle } from "../Constants";
import Loading from "../components/General/Loading";

export default function DetailPage() {
  const { slug } = useParams();
  const history = useHistory();
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: slug ? slug : "404",
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : null;

  if (!isLoading && !story) {
    history.replace('/404');
    return null;
  }

  return (
    <Loading isLoading={isLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: "description",
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 pt-20 sm:pt-40 md:pt-60 text-white">
        <div className="prose px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-lg text-white">
          <h1 className="text-4xl sm:text-6xl md:text-8xl font-medium tracking-tight uppercase">
            {story?.name}
          </h1>
          <PageBody blok={story?.content} />
        </div>
      </div>
    </Loading>
  );
}
