import React, { useEffect, useState } from "react";

import { useStoryblokProxy } from "../../hooks/useStoryblokProxy";
import ToolCard from "./ToolCard";
import Filters from "../Filters";
import { categoryAll } from "../../Constants";

export default function ToolsOverview() {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categoryAll);
  const allCategories = [categoryAll, ...categories];

  const { stories: articleStories } = useStoryblokProxy({
    starts_with: "tools",
    content_type: "tool",
    is_startpage: false,
    per_page: 100,
    filter_query: {
      categories: {
        any_in_array: selectedCategory?.uuid ?? null,
      },
    },
  });
  const { stories: categoryStories } = useStoryblokProxy({
    starts_with: "tools-categories",
  });

  useEffect(() => {
    if (articleStories?.length > 0) {
      setArticles(articleStories);
    }
  }, [articleStories]);

  useEffect(() => {
    if (categoryStories?.length > 0) {
      setCategories(categoryStories);
    }
  }, [categoryStories]);

  function toggleActiveButton(id) {
    setSelectedCategory(allCategories.find((category) => category.id === id));
    allCategories.forEach((category) => {
      category.isActive = category.id === id;
    });
  }

  return (
    <>
      <Filters
        allCategories={allCategories}
        selectedCategory={selectedCategory}
        title="Filter tools"
        toggleActiveButton={toggleActiveButton}
      />
      {articles?.length === 0 && (
        <p className="mb-24 text-2xl">
          No project available with the selected filtering.
        </p>
      )}
      <section className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {articles.map((article) => (
          <ToolCard key={article.uuid} article={article.content} />
        ))}
      </section>
    </>
  );
}
