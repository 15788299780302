import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Header from "../General/Header";
import Footer from "../General/Footer";
import Contact from "../General/Contact";

export default function Layout({ children }) {
  const { authState } = useOktaAuth();
  const [contactOpen, setContactOpen] = useState(false);

  const openContact = () => {
    setContactOpen(true);
  };

  const closeContact = () => {
    setContactOpen(false);
  };

  return (
    <>
      <div className="bg-midnight">
        {authState?.isAuthenticated && <Header openContact={openContact} />}
        {children}
        {authState?.isAuthenticated && <Footer openContact={openContact} />}
      </div>
      {authState?.isAuthenticated && <Contact isOpen={contactOpen} close={closeContact} />}
    </>
  );
}
