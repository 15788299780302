import React from "react";

import { contactEmail } from "../Constants";
import trackButtonClick from "../helpers/trackButtonClick";
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";

export default function People() {
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: "team-members",
    filter_query: {
      showInNavigation: {
        is: true,
      },
    },
  });

  const ringEffectOnHover =
    "rounded-full ring-4 md:ring-8 ring-white group-hover:ring-primary transition-ring duration-200 ease-in-out";

  return (
    <div className="mt-auto text-right">
      <strong className="text-lg">Plan a coffee</strong>
      <ul className="flex justify-end mt-4 flex-wrap">
        {!isLoading && stories?.map((teamMember) => (
          <li
            className="text-center ml-2 md:ml-4 max-w-[30%] group"
            key={teamMember?.uuid}
          >
            <a
              className="group"
              href={teamMember?.content?.calendarLink?.url !== '' ? teamMember?.content?.calendarLink?.url : `mailto:${contactEmail}`}
              onClick={() => trackButtonClick(`coffeeWith${teamMember?.content?.name}`)}
              target={teamMember?.content?.calendarLink?.url !== '' ? '_blank' : ''}
              rel={teamMember?.content?.calendarLink?.url !== '' ? 'nofollow noreferrer noopener' : ''}
            >
              <img
                src={teamMember?.content?.image?.filename}
                alt={teamMember?.content?.image?.alt ?? teamMember?.content?.name}
                width="96"
                height="96"
                className={ringEffectOnHover}
              />
              <p className="mt-2 uppercase">
                {teamMember?.content?.name}
              </p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
