import React from "react";
import { Helmet } from "react-helmet-async";

import Loading from "../components/General/Loading";
import VideoBanner from "../components/VideoBanner";
import Services from "../components/Services";
import USPs from "../components/UPSs";
import Stats from "../components/Stats";
import WorkOverview from "../components/WorkOverview";
import { defaultPageTitle } from "../Constants";
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";

export default function HomePage() {
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: "home",
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : {};

  return (
    <Loading isLoading={isLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: "description",
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <VideoBanner />
      <Services />
      <USPs />
      <div className="bg-midnight">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white">
          <WorkOverview small />
        </div>
      </div>
      <Stats />
    </Loading>
  );
}
