import { Helmet } from 'react-helmet-async';

import Loading from '../components/General/Loading';
import BlogOverview from '../components/Blog/BlogOverview';
import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { defaultPageTitle, work } from '../Constants';
import PageHeading from '../components/General/PageHeading';

export default function BlogPage() {
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: 'blog',
    is_startpage: true,
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : {};

  return (
    <Loading isLoading={isLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: 'description',
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <div className="bg-midnight">
        <div className="relative mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 pt-20 sm:pt-40 md:pt-60 text-white">
          <img
            src="/assets/img/logo-without-text.svg"
            alt=""
            className="absolute w-2/3 blur-2xl opacity-50 pt-8 md:pt-0"
          />
          <div className="z-20 relative">
            <PageHeading
              title={story?.content?.title ?? work}
              description={story?.content?.description}
            />
            <BlogOverview />
          </div>
        </div>
      </div>
    </Loading>
  );
}
