import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Filters from "./Filters";
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";
import ArticleCard from "./ArticleCard";
import { categoryAll } from "../Constants";

export default function WorkOverview({ small }) {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  // TODO: Will be used and tested once we use the big version of this component.
  // eslint-disable-next-line no-unused-vars
  const [selectedCategory, setSelectedCategory] = useState(categoryAll);
  const allCategories = [categoryAll, ...categories];
  const { stories: articleStories } = useStoryblokProxy({
    starts_with: "work",
    is_startpage: false,
    per_page: small ? 4 : 100,
  });
  const { stories: categoryStores } = useStoryblokProxy({
    starts_with: "categories",
  });

  useEffect(() => {
    if (articleStories?.length > 0) {
      setArticles(articleStories);
    }
  }, [articleStories]);

  useEffect(() => {
    if (categoryStores?.length > 0) {
      setCategories(categoryStores);
    }
  }, [categoryStores]);

  function toggleActiveButton(id) {
    setSelectedCategory(allCategories.find((category) => category.id === id));
    allCategories.forEach((category) => {
      category.isActive = category.id === id;
    });
  }

  return (
    <>
      {small ? (
        <div className="flex justify-between items-center mb-12 md:mb-20">
          <h2 className="text-6xl md:text-8xl font-medium tracking-tight uppercase">
            Work
          </h2>

          <Link
            to="/work"
            className="rounded-full text-xs md:text-sm w-20 h-20 md:w-28 md:h-28 px-2 md:px-4 flex items-center text-center tracking-tight lg:w-32 lg:h-32 bg-white text-black hover:bg-primary hover:text-white transition-colors ease-in-out duration-300 uppercase"
          >
            Show all Projects
          </Link>
        </div>
      ) : (
        <Filters
          allCategories={allCategories}
          title="Filter projects"
          toggleActiveButton={toggleActiveButton}
        />
      )}
      <div className="columns-1 md:columns-2 sm:gap-36">
        {articles?.length === 0 && (
          <p className="mb-24 text-2xl">
            No project available with the selected filtering.
          </p>
        )}
        {articles.map((article) => (
          <ArticleCard
            key={article.uuid}
            article={article.content}
            slug={article.full_slug}
            categories={categories}
          />
        ))}
      </div>
    </>
  );
}
