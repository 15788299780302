import { useRef, useState } from "react";

export default function VideoBanner() {
  const vidRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(false);

  function playVideo() {
    vidRef.current.play();
    setShowPlayButton(false);
  }

  function pauseVideo() {
    vidRef.current.pause();
    setShowPlayButton(true);
  }

  return (
    <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 sm:pt-40 md:pt-60 text-white relative">
      <div className="flex items-end z-20 relative">
        <h1 className="text-4xl sm:text-6xl 2xl:text-8xl uppercase font-medium tracking-tight">
          <span className="hidden md:block">
            We accelerate
            <br />
            <span className="border-b-4 inline-block border-white">
              Innovation at pon
            </span>
          </span>
          <span className="block md:hidden pt-28">
            We accelerate
            <br />
            Innovation
            <br />
            <span className="border-b-4 inline-block border-white">at pon</span>
          </span>
        </h1>
        <div className="hidden xl:flex ml-auto relative h-3">
          <span className="uppercase -mt-12 flex items-center h-3">
            Part of the CIO office
            <img
              className="ml-2 h-3"
              src="/assets/img/pon-logo.svg"
              alt="Pon logo"
            />
          </span>
        </div>
      </div>

      <span className="hidden xl:block after:w-20 font-sm absolute uppercase text-white ml-2 bottom-7 xl:bottom-[40%] rotate-[270deg] scroll-animation origin-left">
        Scroll
      </span>
      <div className="relative max-w-5xl ml-auto">
        <img
          src="/assets/img/video-overlay.png"
          className="absolute z-10 h-full w-full"
          alt=""
        />
        <video
          ref={vidRef}
          className="w-full aspect-video -mt-6 md:-mt-8 z-0"
          title="Innovation Lab"
          poster="/assets/img/video-fallback.png"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
          autoPlay
          muted
          playsInline
          loop
        >
          <source src="/assets/video/video.mp4" type="video/mp4" />
        </video>

        {showPlayButton ? (
          <button
            className="absolute z-20 bottom-2 sm:bottom-5 left-2 sm:left-5 w-20 md:w-24 h-20 md:h-24 p-2 bg-black text-white text-xs md:text-sm rounded-full uppercase hover:text-black hover:bg-white"
            onClick={() => playVideo()}
          >
            Play video
          </button>
        ) : (
          <button
            className="absolute z-20 bottom-2 sm:bottom-5 left-2 sm:left-5 w-20 md:w-24 h-20 md:h-24 p-2 bg-black text-white text-xs md:text-sm rounded-full uppercase hover:text-black hover:bg-white"
            onClick={() => pauseVideo()}
          >
            Pause video
          </button>
        )}
      </div>
      <span className="flex xl:hidden uppercase items-center mt-3 text-sm">
        Part of the CIO office
        <img className="ml-2" src="/assets/img/pon-logo.svg" alt="Pon logo" />
      </span>
      <p className="text-3xl sm:text-4xl md:text-6xl text-white py-16 md:py-24 tracking-tight max-w-[90%] md:max-w-[80%] md:leading-snug">
        We accelerate customer centric innovation & support Pon businesses with
        their growth ambitions.
      </p>
    </div>
  );
}
