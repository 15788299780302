import React from "react";
import { Dialog, Transition } from "@headlessui/react";

import CloseButton from "../Buttons/CloseButton";
import People from "../People";
import { contactEmail } from "../../Constants";

export default function Contact({ isOpen, close }) {
  return (
    <Transition as="template" show={isOpen}>
      <Dialog
        as="div"
        className="w-screen fixed z-30 inset-0 h-full overflow-y-scroll"
        onClose={() => close()}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-scroll">
          <Dialog.Panel className="bg-white h-full">
            <div className="mx-auto px-4 sm:px-6 lg:px-20">
              <div className="flex items-center justify-between py-3 md:py-6">
                <img
                  className="h-8 w-auto"
                  src="/assets/img/logo-dark.svg"
                  alt="Innovation Lab Dark"
                />
                <CloseButton close={() => close()} />
              </div>
            </div>
            <div className="bg-white mx-auto max-w-screen-2xl px-3 sm:px-6 lg:px-20 2xl:px-0 flex flex-col pb-10">
              <div className="grow grid content-center mb-10">
                <h1 className="text-4xl md:text-5xl lg:text-7xl font-medium uppercase my-8 md:mb-24 tracking-tighter">
                  Get in touch.
                </h1>
                <div className="flex flex-col gap-y-10 md:flex-wrap md:flex-row lg:flex-no-wrap justify-between">
                  <div className="max-w-sm w-full md:w-1/2 xl:w-1/3 leading-8">
                    <h3 className="font-semibold text-[22px] mb-2">
                      Pon Digital Innovation Lab
                    </h3>
                    <p className="text-lg">
                      Offices FOR YOU <br />
                      Olympisch Stadion 24-28 <br />
                      2nd floor <br />
                      1076 DE Amsterdam
                    </p>
                    <a
                      className="underline hover:text-primary text-lg"
                      href={`mailto:${contactEmail}`}
                    >
                      {contactEmail}
                    </a>
                  </div>
                  <div className="max-w-sm w-full md:w-1/2 lg:w-1/3 leading-8">
                    <h3 className="font-semibold text-[22px] mb-2">
                      Parking
                    </h3>
                    <p className="mb-10 leading-8 text-lg">
                      The Olympic Stadium has an <br />
                      underground car park.{ ' ' }
                      <a
                        className="underline hover:text-primary"
                        target="_blank"
                        href="https://www.interparking.nl/nl-NL/find-parking/Olympisch-Stadion/"
                        rel="noreferrer"
                      >
                        Reservation
                      </a>
                      { ' ' }is
                      <br />
                      recommended.
                    </p>
                    <p className="italic">
                      Note: we are not located at MOVE (Pon HQ) but in the
                      Olympic Stadium.
                    </p>
                  </div>
                  <div className="w-full md:w-full xl:w-1/3 xl:-mt-20">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2437.5042800715646!2d4.853133100000001!3d52.343138599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5e1f9a2b92a89%3A0x30ad69d8004da7db!2sOlympic%20Stadium%2C%20Olympisch%20Stadion%2024%2C%2028%2C%201076%20DE%20Amsterdam!5e0!3m2!1sen!2snl!4v1689599234469!5m2!1sen!2snl"
                      width="100%"
                      height="360"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Google Maps showing the location of the Pon Digital Innovation Lab"
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="md:flex justify-between">
                <div className="group flex flex-col justify-center items-end md:items-start mb-8 md:mb-0 text-lg">
                  <p><strong>Want to stay up to date?</strong></p>
                  <p className="mt-2"><a href="https://pon.us17.list-manage.com/subscribe?u=613bf8acb450448eca76d8432&id=161a0d4cb8" target="_blank" rel="nofollow noopener noreferrer" className="underline hover:text-primary">Subscribe to our newsletter</a></p>
                </div>
              
                <People />
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
