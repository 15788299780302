import React, { useEffect, useState } from 'react';

import { useStoryblokProxy } from '../../hooks/useStoryblokProxy';
import BlogCard from './BlogCard';

export default function BlogOverview({ small = false }) {
  const [articles, setArticles] = useState([]);

  const { stories: articleStories } = useStoryblokProxy({
    starts_with: 'blog',
    is_startpage: false,
    per_page: small ? 4 : 100,
    sort_by: 'first_published_at:desc',
  });

  useEffect(() => {
    if (articleStories?.length > 0) {
      setArticles(articleStories);
    }
  }, [articleStories]);

  return (
    <>
      <div className="columns-1 md:columns-2 sm:gap-36">
        {articles?.length === 0 && (
          <p className="mb-24 text-2xl">No blogs available.</p>
        )}

        {articles.map((article) => (
          <BlogCard
            key={article.uuid}
            article={article.content}
            slug={article.full_slug}
            createdData={new Date(article.created_at).toString()}
          />
        ))}
      </div>
    </>
  );
}
