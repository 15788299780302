import React from "react";

import LinkSlot from "./LinkSlot";
import { contactEmail } from "../Constants";

export default function TeamMembers({ teamMembers, teamMemberIds }) {
  return (
    <div className="flex -space-x-1 md:-space-x-3">
      {teamMemberIds.map((member, index) => {
        let teamMemberData = teamMembers.find((data) => data.uuid === member);

        return (
          <div
            className="group relative mb-2"
            key={teamMemberData.id}
            style={{ zIndex: 20 - index }}
          >
            <LinkSlot
              url={
                teamMemberData?.content?.calendarLink?.url
                  ? teamMemberData?.content?.calendarLink?.url
                  : `mailto:${contactEmail}`
              }
            >
              <img
                src={teamMemberData?.content?.image?.filename}
                alt={`Team member ${teamMemberData?.content?.image?.alt}`}
                className="inline-block h-[2.5rem] w-[2.5rem] md:h-[3rem] md:w-[3rem] lg:h-[4.5rem] lg:w-[4.5rem] rounded-full ring-4 md:ring-8 ring-midnight group-hover:ring-primary transition-ring duration-200 ease-in-out"
              />
              <p className="text-xs md:text-base absolute rounded -bottom-9 md:-bottom-12 left-1/2 transform -translate-x-5 lg:-translate-x-10 xl:-translate-x-1/3 w-max hidden group-hover:block bg-black text-white px-2 py-1">
                {teamMemberData?.content?.calendarLink?.url &&
                  "Plan a coffee with"}{" "}
                {teamMemberData?.content?.name}
              </p>
            </LinkSlot>
          </div>
        );
      })}
    </div>
  );
}
