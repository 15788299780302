export default function PageHeading({ title, description }) {
  return (
    <>
      <h1 className="text-5xl sm:text-7xl md:text-8xl mt-8 lg:mt-0 uppercase font-medium border-b-2 pb-6 inline-block tracking-tighter">
        {title}
      </h1>
      {description && (
        <p className="text-xl sm:text-2xl md:text-3xl text-white py-12 md:py-20 tracking-tight max-w-[80%] md:max-w-[50%] md:leading-normal">
          {description}
        </p>
      )}
    </>
  );
}
