import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  matchPath,
} from "react-router-dom";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { createBrowserHistory } from "history";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import * as Sentry from "@sentry/react";

import { authClient } from "./auth/authClient";
import "./App.css";
import Layout from "./components/Layouts/Layout";
import HomePage from "./pages/HomePage";
import ToolsPage from "./pages/ToolsPage";
import { Component, useEffect, useRef } from "react";
import ErrorFallback from "./components/General/ErrorFallback";
import WorkPage from "./pages/WorkPage";
import WorkDetailPage from "./pages/WorkDetailPage";
import WhatWeDoPage from "./pages/WhatWeDoPage";
import DetailPage from "./pages/DetailPage";
import WhatWeDoDetailPage from "./pages/WhatWeDoDetailPage";
import ScrollToTop from "./components/General/ScrollToTop";
import handleAnalyticsConsent from "./helpers/analyticsConsentHandler";
import BlogPage from "./pages/BlogPage";
import BlogDetailPage from "./pages/BlogDetailPage";

const history = createBrowserHistory();
const queryClient = new QueryClient();

const APP_ROUTES = [
  {
    path: "/login/callback",
    exact: false,
    component: () => (
      <LoginCallback
        errorComponent={() => (
          <ErrorFallback
            renderReason={() => <>Oops!</>}
            renderMessage={() => <>You don't have access to this application.</>}
            authError={true}
          />
        )}
      />
    ),
    isPrivate: false,
  },
  {
    path: "/",
    exact: true,
    component: HomePage,
    isPrivate: true,
  },
  {
    path: "/tools",
    exact: true,
    component: ToolsPage,
    isPrivate: true,
  },
  {
    path: "/work",
    exact: true,
    component: WorkPage,
    isPrivate: true,
  },
  {
    path: "/work/:slug",
    exact: true,
    component: WorkDetailPage,
    isPrivate: true,
  },
  {
    path: "/blog",
    exact: true,
    component: BlogPage,
    isPrivate: true,
  },
  {
    path: "/blog/:slug",
    exact: true,
    component: BlogDetailPage,
    isPrivate: true,
  },
  {
    path: "/what-we-do",
    exact: true,
    component: WhatWeDoPage,
    isPrivate: true,
  },
  {
    path: "/what-we-do/:subject/:slug",
    exact: true,
    component: WhatWeDoDetailPage,
    isPrivate: true,
  },
  {
    path: "/404",
    component: () => (
      <ErrorFallback
        renderReason={() => <>404</>}
        renderMessage={() => <>Page not found</>}
        authError={true}
      />
    )
  },
  {
    path: "/:slug",
    exact: true,
    component: DetailPage,
    isPrivate: true,
  },
  {
    path: "*",
    component: () => (
      <ErrorFallback
        renderReason={() => <>404</>}
        renderMessage={() => <>Page not found</>}
        authError={true}
      />
    ),
  },
];

Sentry.init({
  dsn: "https://494a629182d9438e327348acf90e3ed4@o230677.ingest.sentry.io/4506667989794816",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        APP_ROUTES,
        matchPath
      ),
    }),
  ],
  enabled: process.env.REACT_APP_SENTRY_ENVIRONMENT === "production",
  tracesSampleRate: 1.0,
});

const App = () => {
  const prevLocation = useRef();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {},
      },
      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                "We use cookies to, for example, improve and analyze the website. By clicking on 'I agree' you give Pon permission for the use of necessary and optimal cookies on our website. If you want to know more about these cookies, you can consult our Cookie Policy",
              acceptAllBtn: "Accept",
              acceptNecessaryBtn: "Decline",
              showPreferencesBtn: "Learn more and customize",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Decline all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close",
              sections: [
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Google Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
        },
      },
      onConsent: () => handleAnalyticsConsent(),
      onChange: () => handleAnalyticsConsent(),
    });
  }, []);

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname !== prevLocation.current) {
        prevLocation.current = location.pathname;

        // Redirect to the correct page.
        window.location.href = location.pathname;
      }
    });
  }, []);

  return (
    <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ScrollToTop />
          <Layout>
            <Switch>
              {APP_ROUTES.map(
                ({ path, exact, component: Component, isPrivate }) => {
                  if (isPrivate === true) {
                    return (
                      <SecureRoute key={path} path={path} exact={exact}>
                        <Component />
                      </SecureRoute>
                    );
                  }
                  return (
                    <Route key={path} path={path} exact={exact}>
                      <Component />
                    </Route>
                  );
                }
              )}
            </Switch>
          </Layout>
        </HelmetProvider>
      </QueryClientProvider>
    </Security>
  );
};

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <Router>
        <AppWithRouterAccess />
      </Router>
    );
  }
}

export default RouterApp;
