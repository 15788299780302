export default function Images({ blok }) {
  const imageCount = blok.images.length;

  return (
    <div
      className={`image-gallery my-8 ${imageCount > 0 ? "grid gap-12" : ""} ${
        imageCount === 2 ? "grid-cols-2" : ""
      } ${imageCount >= 3 ? "grid-cols-3" : ""}`}
    >
      {blok?.images.map((image) => (
        <img
          key={image.id}
          className="!mb-0"
          src={image?.filename}
          alt={image?.alt}
        />
      ))}
    </div>
  );
}
