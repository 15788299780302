import React from "react";
import { Link } from "react-router-dom";

export default function ArticleCard({
  article,
  slug,
  categories,
  relatedItem = false,
}) {
  const skills = categories?.filter((category) =>
    article.categories.includes(category.uuid)
  );

  return (
    <div className="break-inside-avoid-column mb-16 sm:mb-20">
      <div>
        <Link to={`/${slug}`} tabIndex="-1">
          <img
            src={article?.hero?.filename}
            alt={article?.hero?.alt}
            className={`h-full w-full lg:h-full lg:w-full ${
              relatedItem ? "aspect-video object-cover" : ""
            }`}
          />
        </Link>
      </div>
      <div className="mt-4">
        <div>
          <h3 className="uppercase !text-base !font-normal">
            {article?.client}
          </h3>
          <Link
            to={`/${slug}`}
            className="text-3xl sm:text-4xl font-medium mt-2 tracking-tight !leading-[2.75rem] !text-white"
          >
            {article?.title}
          </Link>
          <p className="mt-4 uppercase">{article?.skills}</p>
          <ul className="flex mt-2">
            {skills?.map((category, index) => (
              <li key={category?.uuid}>
                {category?.name}
                {index !== skills.length - 1 && (
                  // Make clear that the '//' isn't to define a comment.
                  // eslint-disable-next-line react/jsx-no-comment-textnodes
                  <span className="inline-block px-2">//</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
