/**
 * Limit the length of a text.
 *
 * @param {string} text Text to limit the characters of.
 * @param {number} characterLimit Maximum amount of characters you want.
 */
export function limitTextLength(text = '', characterLimit = 120) {
  if (text.length > characterLimit) {
    return text.substring(0, characterLimit) + '...';
  }

  return text;
}
