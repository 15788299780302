import React from "react";

import StatsCard from "./StatsCard"; // Import the StatsCard component
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";

export default function Stats() {
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: "stats",
  });

  if (!isLoading && stories?.length > 0) {
    return (
      <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white">
        <div className="container mx-auto grid cols-2 md:grid-cols-4 gap-12 mt-12 lg:mt-24 pb-20 lg:pb-6 place-items-center">
          {stories.map((statistic) => (
            <StatsCard
              key={statistic?.uuid}
              amount={statistic?.content?.number}
              title={statistic?.content?.title}
            />
          ))}
        </div>
      </div>
    );
  }
}
