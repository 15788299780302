import React from "react";
import ReactGA from "react-ga4";

export default function ToolCard({ article }) {
  return (
    <article className="bg-purple">
      {article.image?.filename && (
        <img
          src={`${article.image.filename}/m/1600x0`}
          alt={article.image.alt}
          className="w-full object-cover object-top h-48"
        />
      )}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72.781"
        height="24.334"
        viewBox="0 0 72.781 24.334"
        className="fill-purple -mt-6"
      >
        <path
          d="M35.074 4.666C52.908 4.666 61.858 29 71.336 29H0C9.848 29 17.24 4.666 35.074 4.666z"
          transform="translate(.749 -4.666)"
        />
      </svg>

      <a
        href={article.pdf.filename}
        onClick={(e) => {
          e.preventDefault(); 
          
          ReactGA.event({
            category: 'PDF',
            action: 'Download',
            label: `Download Tools - ${article.title}`
          });

          window.open(article.pdf.filename, '_blank', 'nofollow noreferrer noopener');
        }}
        target="_blank"
        rel="nofollow noreferrer noopener"
        className="bg-primary hover:bg-white group rounded-full w-8 h-8 flex items-center justify-center -mt-4 z-10 relative ml-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.214"
          height="16.214"
          viewBox="0 0 16.214 16.214"
          className="fill-white group-hover:fill-primary"
        >
          <path d="M9.12 1.013a1.013 1.013 0 0 0-2.027 0V8.7L4.769 6.375a1.014 1.014 0 0 0-1.434 1.434l4.053 4.053a1.015 1.015 0 0 0 1.435 0l4.053-4.053a1.014 1.014 0 0 0-1.435-1.435L9.12 8.7zM2.027 11.147A2.029 2.029 0 0 0 0 13.174v1.013a2.029 2.029 0 0 0 2.027 2.027h12.16a2.029 2.029 0 0 0 2.027-2.027v-1.013a2.029 2.029 0 0 0-2.027-2.027h-3.214l-1.435 1.435a2.026 2.026 0 0 1-2.866 0l-1.431-1.435zM13.68 12.92a.76.76 0 1 1-.76.76.76.76 0 0 1 .76-.76z" />
        </svg>
      </a>
      <div className="px-5 pt-3 pb-6">
        <h4 className="text-lg font-semibold leading-5">{article.title}</h4>
        {article?.description && (
          <p className="mt-3 text-darkgray text-sm lg:text-base leading-snug">
            {article.description}
          </p>
        )}
      </div>
    </article>
  );
}
