/**
 * Format a date.
 *
 * @param {string} dateString String of the date to format.
 */
export function formatDate(dateString = '') {
  if (dateString === '') {
    return dateString;
  }
  const output = new Date(dateString);
  return output.toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}
