import { render } from "storyblok-rich-text-react-renderer";

export default function Deliverables({ blok }) {
  return (
    <div className="deliverables">
      <h1 className="text-white text-2xl font-semibold">
        {blok?.title ? blok.title : 'Deliverables'}
      </h1>
      <div>
        <div className="list-none [&>ul]:pl-0">
          {render(blok?.deliverable_list_items)}
        </div>
      </div>
    </div>
  );
}
