import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from '../../helpers/formatDate';
import { limitTextLength } from '../../helpers/limitTextLength';

export default function BlogCard({
  article,
  slug,
  relatedItem = false,
  createdData,
}) {
  return (
    <div className="break-inside-avoid-column mb-16 sm:mb-20">
      <div>
        <Link to={`/${slug}`} tabIndex="-1">
          <img
            src={article?.hero?.filename}
            alt={article?.hero?.alt}
            className={`h-full w-full lg:h-full lg:w-full ${
              relatedItem ? 'aspect-video object-cover' : ''
            }`}
          />
        </Link>
      </div>
      <div className="mt-4">
        <div>
          <h3 className="uppercase !text-base !font-normal">
            {article?.subject}
          </h3>
          <Link
            to={`/${slug}`}
            className="text-3xl sm:text-4xl font-medium mt-2 tracking-tight !leading-[2.75rem] !text-white"
          >
            {article?.title}
          </Link>
          <p className="my-4 uppercase">{formatDate(createdData)}</p>
          <p>{limitTextLength(article.shortIntro)}</p>
        </div>
      </div>
    </div>
  );
}
