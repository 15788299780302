export const listItems = [
  {
    title: "New Concepts",
    description:
      "Using Design Thinking methodology, we support in creating new, innovative concepts that add value to both customers and business.",
    relation: "new-concepts",
  },
  {
    title: "Strategy Work",
    description:
      "On one hand we help existing Pon companies with their strategic business challenges. On the other hand we support Pon start-ups with their go to market approach and growth plans to scale their business.",
    relation: "strategy-work",
  },
  {
    title: "Trends & Events",
    description:
      "We look at the world around us and identify interesting business and technology opportunities. We bring what happens in the outside world into the lab by working with startups on new propositions and organizing tech challenges and knowledge sharing events.",
    relation: "trends-events",
  },
  {
    title: "Training",
    description:
      "We aim to provide Pon employees with the mindset, skills and tools to accelerate innovation in their day-to-day work. Customer centricity, iterating and experimenting by doing are central topics.",
    relation: "training",
  },
];

export const contentType = {
  richText: "richText",
  indent: "indent",
  images: "images",
  page: "page",
  deliverables: "deliverables",
  quote: "quote",
  video: "video",
  whatWeDoOverview: "whatWeDoOverview",
  table: "table",
};

export const categoryAll = {
  id: "all",
  name: "All",
  isActive: true,
};

export const defaultPageTitle = "Digital Innovation Lab";

export const tools = "Tools";
export const whatWeDo = "What We Do";
export const work = "Work";
export const contactEmail = "digitalinnovationlab@pon.com";

export const whatWeDoHeaders = {
  "trends-events": "Trends & events",
  "strategy-work": "Strategy work",
  "new-concepts": "New concepts",
  training: "Training",
};
