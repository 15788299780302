import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CloseButton from "../Buttons/CloseButton";
import People from "../People";
import trackButtonClick from "../../helpers/trackButtonClick";

export default function Header({ openContact }) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <nav className="bg-midnight fixed top-0 w-full pb-2 z-30">
        <div className="mx-auto px-4 sm:px-6 lg:px-20">
          <div className="flex items-center justify-between py-3 md:py-6">
            <Link to="/">
              <img
                className="block h-8 w-auto"
                src="/assets/img/logo.svg"
                alt="Innovation Lab"
              />
            </Link>
            <div className="relative md:ml-3 text-white">
              <div className="flex">
                <button
                  className="cursor-pointer rounded-3xl px-3 h-10 sm:h-12 sm:px-6 flex items-center text-xs sm:text-base uppercase ring-1 ring-inset ring-white hover:bg-white hover:text-midnight"
                  onClick={() => {
                    trackButtonClick('GetInTouchButton');
                    openContact();
                  }}
                >
                  Get in touch
                </button>
                <button
                  onClick={() => setMenuOpen(true)}
                  className="flex flex-col justify-center items-center rounded-full w-10 sm:w-12 h-10 sm:h-12 ml-4 text-sm sm:text-base ring-1 ring-inset ring-white hover:bg-white group"
                >
                  {/* Hamburger Menu Icon */}
                  <span className="border-b border-white w-4 group-hover:border-black"></span>
                  <span className="border-b border-white w-4 mt-2 group-hover:border-black"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Transition as="template" show={menuOpen}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => setMenuOpen(false)}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <Transition.Child
                  as="div"
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen h-screen max-w-4xl pl-10 sm:pl-16">
                    <div className="flex h-full flex-col justify-between overflow-y-scroll bg-white py-3 md:py-6 shadow-xl text-end">
                      <div className="px-4 sm:px-6 lg:px-20">
                        <CloseButton close={() => setMenuOpen(false)} />
                      </div>
                      <div className="mt-24 md:mt-36 flex-1 px-4 sm:px-6 lg:px-20">
                        <div className="flex flex-col h-full">
                          <nav className="flex flex-col">
                            <MenuItem
                              title="All work"
                              url="/work"
                              setMenuOpen={setMenuOpen}
                            />
                            <MenuItem
                              title="What We Do"
                              url="/what-we-do"
                              setMenuOpen={setMenuOpen}
                            />
                            <MenuItem
                              title="Blogs"
                              url="/blog"
                              setMenuOpen={setMenuOpen}
                            />
                            <MenuItem
                              title="Tools"
                              url="/tools"
                              setMenuOpen={setMenuOpen}
                            />
                          </nav>
                          <People />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export function MenuItem({ title, url, setMenuOpen }) {
  return (
    <Link
      to={url}
      className="text-4xl md:text-5xl font-semibold inline ml-auto border-b-4 border-white hover:text-primary hover:border-b-4 hover:border-primary [&:not(:first-child)]:mt-4"
      onClick={() => setMenuOpen(false)}
    >
      {title}
    </Link>
  );
}
