import React from "react";

export default function Filters({
  allCategories,
  selectedCategory,
  title,
  toggleActiveButton,
}) {
  return (
    <div className="mb-12 md:mb-20">
      {title && <p className="font-semibold">{title}</p>}
      <div className="flex flex-wrap gap-2 md:gap-4 mt-4">
        {allCategories.map((category) => (
          <button
            key={category.id}
            className={`text-center rounded-full px-6 sm:px-8 py-1 text-sm md:text-md lg:text-lg uppercase border border-gray ${
              category.id === selectedCategory.id ? "bg-white text-black" : ""
            }`}
            onClick={() => toggleActiveButton(category.id)}
          >
            {category?.name || ""}
          </button>
        ))}
      </div>
    </div>
  );
}
