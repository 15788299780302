import { useQuery } from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";
import qs from "qs";

export const ERROR_MESSAGE = "Failed to fetch stories response";

export function useStoryblokProxy(args, abortRequest) {
  const { oktaAuth } = useOktaAuth();
  const idToken = oktaAuth.getIdToken();

  const params = {
    ...args,
    resolve_relations: args.resolve_relations?.join(","),
    version: process.env.REACT_APP_STORYBLOK_CONTENT_STAGE ?? "draft",
  };

  if (params?.filter_query?.categories) {
    // If any_in_array is null, delete it to prevent errors.
    if (
      "any_in_array" in params?.filter_query?.categories &&
      !params?.filter_query?.categories?.any_in_array
    ) {
      delete params.filter_query.categories.any_in_array;
    }
  }

  const options = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  const { isLoading, error, data } = useQuery({
    queryKey: [
      "innovation-lab-website",
      args.start_with + qs.stringify(params),
    ],
    queryFn: async () => {
      const response = await fetch(
        `${
          process.env.REACT_APP_STORYBLOK_PROXY_URL
        }/cdn/stories?${qs.stringify(params)}`,
        options
      );
      if (!response.ok) {
        throw new Error(ERROR_MESSAGE);
      }
      return response.json();
    },
    select: (data) => ({
      stories: data.stories,
      rels: data.rels,
      rel_uuids: data.rel_uuids,
    }),
    enabled: !abortRequest,
  });

  return {
    stories: data?.stories,
    rels: data?.rels,
    rels_uuids: data?.rel_uuids,
    isLoading,
    error,
  };
}
