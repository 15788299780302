import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Loading from '../components/General/Loading';
import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { PageBody } from '../storyblok/PageBody';
import { defaultPageTitle } from '../Constants';
import { formatDate } from '../helpers/formatDate';

export default function BlogDetailPage() {
  const { slug } = useParams();
  const history = useHistory();
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: slug ? `blog/${slug}` : '404',
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : null;

  if (!isLoading && !story) {
    history.replace('/404');
    return null;
  }

  return (
    <Loading isLoading={isLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: 'description',
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <div className="text-white">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 pt-20 sm:pt-40 md:pt-60 text-white relative">
          {story?.content?.subject && (
            <p className="md:flex mb-2 text-subtitle uppercase text-lg tracking-tight">
              {story?.content?.subject}
            </p>
          )}
          <h1 className="text-4xl md:text-5xl leading-tight md:leading-snug lg:leading-tight lg:text-7xl uppercase font-medium lg:max-w-[80%]">
            {story?.content?.title}
          </h1>
          <span className="block after:w-20 font-sm absolute uppercase text-white right-1 lg:mr-2 bottom-7 lg:bottom-10 rotate-[270deg] scroll-animation">
            Scroll
          </span>
          <p className="uppercase text-lg mb-2 text-subtitle tracking-tight">
            {formatDate(new Date(story?.created_at).toString())}
          </p>
        </div>
        <figure className="mt-12 relative">
          <img
            src="/assets/img/video-overlay.png"
            className="absolute z-10 h-full w-full object-cover"
            alt=""
          />
          <img
            v-if="blok.hero?.filename"
            src={`${story?.content?.hero?.filename}/m/1600x0`}
            alt={story?.content?.hero?.alt}
            className="w-full max-h-[65vh] object-cover"
          />
        </figure>
        <PageBody
          className="case flex flex-col px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-lg mt-12 lg:mt-20 prose text-white"
          blok={story?.content}
        />
      </div>
    </Loading>
  );
}
