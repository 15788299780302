import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Loading from "../components/General/Loading";
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";
import { PageBody } from "../storyblok/PageBody";
import { defaultPageTitle } from "../Constants";
import RelatedWorkItem from "../components/RelatedWorkItem";

export default function WhatWeDoDetailPage() {
  const { subject, slug } = useParams();
  const history = useHistory();
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: subject && slug ? `what-we-do/${subject}/${slug}` : "404",
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : null;

  const { isLoading: isRelatedWorkLoading, stories: relatedStories } =
    useStoryblokProxy({
      starts_with: "work",
      is_startpage: false,
      per_page: 2,
    });

  // We filter out the items that are already selected as featured items.
  const relatedWork =
    !isLoading && !isRelatedWorkLoading
      ? relatedStories.filter(function (el) {
          return !story?.content?.relatedWork?.includes(el?.uuid);
        })
      : [];

  if (!isLoading && !story) {
    history.replace("/404");
    return null;
  }

  return (
    <Loading isLoading={isLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: "description",
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <div className="bg-midnight what-we-do-detail">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 pt-20 sm:pt-40 md:pt-60 text-white">
          <div className="relative">
            <img
              src="/assets/img/logo-without-text.svg"
              alt=""
              className="absolute top-0 left-0 w-2/3 blur-2xl opacity-50"
            />
          </div>
        </div>

        <div className="prose px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-lg text-white">
          <div className="w-full flex flex-col">
            <div className="mt-8 -mb-12 z-20 relative">
              <div className="uppercase mb-0.5 text-subtitle">
                {subject?.replace("-", " ")}
              </div>
              <h1 className="text-4xl md:text-6xl lg:text-7xl uppercase font-medium tracking-tight border-b-2 inline-block">
                {story?.content?.title}
              </h1>
              <h3 className="text-md lg:text-xl lg:w-5/6 mt-8 lg:mt-12 mb">
                {story?.description}
              </h3>
            </div>
            <PageBody className="z-10" blok={story?.content} />

            <div className="z-10">
              <h3 className="text-md lg:text-xl mb-0">Related work</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-16 not-prose">
                {story?.content?.relatedWork?.length > 0 &&
                  story?.content?.relatedWork?.map((relatedWorkItem) => {
                    return (
                      <RelatedWorkItem
                        key={relatedWorkItem}
                        relatedWorkItemId={relatedWorkItem}
                      />
                    );
                  })}
                {/* Add additional related work if less then two items are selected. */}
                {!isRelatedWorkLoading &&
                  relatedWork.length > 0 &&
                  (story?.content?.relatedWork?.length < 2 ||
                    !story?.content?.relatedWork) &&
                  (story?.content?.relatedWork?.length === 0 ||
                  !story?.content?.relatedWork ? (
                    relatedWork?.map((relatedWorkItem) => {
                      return (
                        <RelatedWorkItem
                          key={relatedWorkItem?.uuid}
                          relatedWorkItemId={relatedWorkItem?.uuid}
                        />
                      );
                    })
                  ) : (
                    <RelatedWorkItem
                      key={relatedWork[0]?.uuid}
                      relatedWorkItemId={relatedWork[0]?.uuid}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Loading>
  );
}
