import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import * as CookieConsent from 'vanilla-cookieconsent';

import { useStoryblokProxy } from "../../hooks/useStoryblokProxy";
import removeLeadingSlash from "../../helpers/removeLeadingSlash";
import LinkSlot from "../LinkSlot";
import { contactEmail } from "../../Constants";

export default function Footer({ openContact }) {
  let story = {};
  const location = useLocation();
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: removeLeadingSlash(location.pathname),
  });
  const [personToGetContacted, setPersonToGetContacted] = useState(null);

  if (!isLoading && stories?.length > 0) {
    story = stories[0];
  }

  const { isLoading: isTeamMembersLoading, stories: teamMemberStories } =
    useStoryblokProxy({
      starts_with: "team-members",
      by_uuids: story?.content?.teamMembers
        ? story?.content?.teamMembers.join(",")
        : "",
    });

  useEffect(() => {
    if (!isTeamMembersLoading && story?.content?.teamMembers?.length > 0) {
      setPersonToGetContacted(
        teamMemberStories.find(
          (teamMemberData) =>
            teamMemberData.uuid === story?.content?.teamMembers[0]
        )
      );
    } else {
      setPersonToGetContacted(null);
    }
    // Adding 'story' as a dependency will cause errors with Cloudflare pages.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamMembersLoading, teamMemberStories]);

  return (
    <>
      <div className="bg-primary py-12 sm:py-16 mt-8 md:mt-32">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white">
          <div className="xl:flex xl:items-center lg:gap-x-8">
            <h2
              className={`text-4xl font-medium uppercase tracking-tighter ${
                personToGetContacted
                  ? "sm:text-5xl md:text-4xl"
                  : "sm:text-6xl md:text-8xl"
              }`}
            >
              {personToGetContacted ? "Like what you see?" : "Get in touch"}
            </h2>
            <div className="md:ml-auto mt-8 xl:mt-0 sm:flex items-center gap-x-2 xl:gap-x-4 text-lg group">
              {personToGetContacted ? (
                <LinkSlot
                  url={
                    personToGetContacted?.content?.calendarLink?.url
                      ? personToGetContacted?.content?.calendarLink?.url
                      : `mailto:${contactEmail}`
                  }
                >
                  <p className="mb-2 mr-6 sm:mb-0 inline-block group-hover:underline">
                    Let's exchange thoughts with{" "}
                    {personToGetContacted?.content?.name}
                  </p>
                  <img
                    src={personToGetContacted?.content?.image?.filename}
                    alt={
                      "Team member " + personToGetContacted?.content?.image?.alt
                    }
                    className="inline-block w-[4rem] rounded-full"
                  />
                </LinkSlot>
              ) : (
                <button
                  className="group md:flex items-center"
                  onClick={() => openContact()}
                >
                  <p className="max-sm:text-start">
                    Like what you see? Let's exchange thoughts
                  </p>
                  <span className="pl-6 transform transition duration-500 ease-in-out group-hover:translate-x-8 mt-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="245.103"
                      height="26.871"
                      viewBox="0 0 245.103 26.871"
                    >
                      <path
                        data-name="Path 76"
                        d="M1416.186 3793.666h243.684"
                        transform="translate(-1416.186 -3780.231)"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <path
                        data-name="Path 77"
                        d="m1482.552 3776.654 12.728 12.728-12.728 12.728"
                        transform="translate(-1251.592 -3775.947)"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="py-10">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white">
          <div className="sm:flex sm:items-center">
            <Link to="/">
              <img
                className="block h-8 w-auto"
                src="/assets/img/logo.svg"
                alt="Innovation Lab"
              />
            </Link>
            <div className="mt-8 sm:ml-auto sm:mt-0 text-sm">
              <nav className="max-sm:flex max-sm:flex-col items-start">
                <Link
                  to="/cookie-policy"
                  className="hover:text-primary font-semibold"
                >
                  Cookie policy
                </Link>
                <button
                  className="hover:text-primary font-semibold max-sm:mt-2 sm:pl-6"
                  type="button"
                  onClick={CookieConsent.showPreferences}
                >
                  Cookie preferences
                </button>
                <Link
                  to="/privacy-statement"
                  className="hover:text-primary font-semibold max-sm:mt-2 sm:pl-6"
                >
                  Privacy statement
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
