export default function Quote({ blok }) {
  return (
    <div className="flex flex-wrap items-center">
      <div className="md:w-8/12 md:pr-16 lg:pr-24 mt-5">
        <span className="text-7xl leading-none whitespace-nowrap not-sr-only absolute lg:-mt-10">
          “
        </span>
        <p className="text-2xl pt-2 lg:!mt-0 lg:pt-0">{blok.quote}</p>
        {blok?.name && (
          <strong className="text-3xl lg:text-4xl text-white block font-medium mb-2">
            {blok.name}
          </strong>
        )}
        {blok?.function && (
          <span className="lg:text-lg uppercase">{blok.function}</span>
        )}
      </div>
      <div className="md:w-4/12">
        {blok?.photo && (
          <img
            className="w-32 md:w-full"
            src={blok?.photo?.filename}
            alt={blok?.photo?.alt}
          />
        )}
      </div>
    </div>
  );
}
