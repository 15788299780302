import React from "react";
import { Helmet } from "react-helmet-async";

import Loading from "../components/General/Loading";
import ToolsOverview from "../components/Tools/ToolsOverview";
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";
import { defaultPageTitle, tools } from "../Constants";
import PageHeading from "../components/General/PageHeading";

export default function ToolsPage() {
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: "tools",
    is_startpage: true,
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : {};

  return (
    <Loading isLoading={isLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: "description",
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <div className="bg-midnight">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 pt-20 sm:pt-40 md:pt-60 text-white">
          <PageHeading
            title={story?.content?.title ?? tools}
            description={story?.content?.description}
          />
          <ToolsOverview />
        </div>
      </div>
    </Loading>
  );
}
