export default function USPs() {
  return (
    <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white">
      <div className="container mx-auto grid md:grid-cols-3 gap-12 my-28">
        <USPsCard
          title="We are pon"
          description="We know the Pon companies, understand their challenges, culture and
          context."
        />
        <USPsCard
          title="We are pragmatic"
          description=" With our tailored approach and years of experience, we make innovation
          practical."
        />
        <USPsCard
          title="We make impact"
          description="We deliver quality output and help Pon companies to become more
          innovative on the long run."
        />
      </div>
    </div>
  );
}

export function USPsCard({ title, description }) {
  return (
    <div className="max-w-sm">
      <h3 className="text-3xl font-semibold mb-2">{title}</h3>
      <p>{description}</p>
    </div>
  );
}
