import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useGSAP } from "@gsap/react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import { listItems } from "../Constants";

export default function Services() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const main = useRef(null);
  const [isActive, setIsActive] = useState({
    "new-concepts": true,
    "strategy-work": false,
    "trends-events": false,
    training: false,
  });

  useGSAP(() => {
    const panels = document.querySelectorAll(".panel");
    const lines = document.querySelectorAll(".line");

    panels.forEach((panel, index) => {
      if (window.innerWidth < 1024) {
        gsap.to(lines[index], {
          scrollTrigger: {
            trigger: panel,
            start: "top center",
            end: "+=75%",
            scrub: true,
            pin: true,
            onUpdate: (self) => {
              self.pinSpacing = { bottom: 0 };
              if (self.progress >= 0.75) {
                if (!self.pinned) {
                  self.end = "+=400"; // Lock the pinned element at its current position
                  gsap.to(panel, { opacity: 0 }); // Fade out and remove the pinned element
                }
              } else {
                gsap.to(panel, { opacity: 1 }); // Make the pinned element fully opaque
              }
            },
            onToggle: (self) => {
              self.isActive && setActive(panel);
            },
          },
        });
      } else {
        gsap.to(lines[index], {
          scrollTrigger: {
            trigger: panel,
            start: "center center",
            end: "+=75%",
            scrub: true,
            pin: true,
            onToggle: (self) => {
              self.isActive && setActive(panel);
            },
          },
        });
      }
    });

    const links = gsap.utils.toArray('.link');
    links.forEach((a) => {
      let element = document.querySelector(a.getAttribute("href"));
      let linkST = ScrollTrigger.create({
        trigger: element,
        start: "center center",
      });
      ScrollTrigger.create({
        trigger: element,
        start: "top center",
        end: "bottom center",
        onToggle: (self) => {
          self.isActive && setActive(a);
        },
      });
      a.addEventListener("click", (e) => {
        e.preventDefault();
        gsap.to(window, {
          duration: 1,
          scrollTo: linkST.start,
          overwrite: "auto",
        });
      });
    });

    function setActive(link) {
      setIsActive((prevState) => {
        const newState = {};
        Object.keys(prevState).forEach((key) => {
          if (key === link?.href?.split("#")[1] || key === link.id) {
            newState[key] = true;
          } else {
            newState[key] = false;
          }
        });
        return newState;
      });
    }

    // Resize event listener if your layout changes on window resize
    window.addEventListener('resize', () => {
      ScrollTrigger.refresh();
    });

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', () => {
        ScrollTrigger.refresh();
      });
    };
  }, []);

  // Create a new const with the link elements.
  // This time without an eventListener atteched to it.
  const scrollLinks = gsap.utils.toArray('.link');
  useEffect(() => {
    // Refresh ScrollTrigger when scrollLinks are changed.
    // This is to ensure all elements have been loaded and their dimensions are set.
    ScrollTrigger.refresh();
  }, [scrollLinks]);

  return (
    <div className="grid lg:grid-cols-7 gap-x-8 px-4 lg:px-20 lg:pb-20 relative">
      <div
        className="absolute bg-fixed z-10 h-full w-full top-0 left-0 bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url('/assets/img/services-bg.jpg')` }}
      />
      <div
        className="absolute bg-fixed z-10 h-full w-full top-0 left-0 bg-black opacity-80 bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url('/assets/img/video-overlay.png')` }}
      />
      <div className="sticky mt-20 lg:col-span-3 top-24 md:top-32 lg:top-[20%] left-0 h-screen text-white z-20">
        <nav className="flex flex-col items-start justify-start lg:justify-center gap-y-0 lg:gap-y-2">
          <p className="text-3xl md:text-4xl lg:text-6xl xl:text-7xl uppercase font-medium mb-4 lg:mb-8">
            What we do
          </p>
          {listItems.map((listItem) => (
            <div key={listItem.title}>
              <a href={`#${listItem.relation}`} className="link uppercase">
                <p
                  className={`text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold py-1 md:py-4 lg:py-6 px-1 lg:px-3 ${
                    isActive?.[listItem.relation]
                      ? "bg-primary transition-colors ease-in-out duration-500"
                      : ""
                  }`}
                >
                  {listItem.title}
                </p>
              </a>
              <Transition
                appear
                show={!!isActive?.[listItem.relation]}
                enter="transition ease-in-out duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-in-out duration-0"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="pl-4 py-4 text-sm lg:text-base leading-6 lg:leading-7 flex flex-col gap-y-2 lg:gap-y-4"
              >
                <p>{listItem.description}</p>
                <Link
                  to="/work"
                  className="flex items-center gap-x-2 hover:opacity-80 transition-opacity ease-in-out duration-300"
                >
                  <span className="text-xs lg:text-base uppercase font-semibold">
                    See work
                  </span>
                  <img
                    src="/assets/img/right-arrow.svg"
                    alt="Right arrow"
                    className="w-4 lg:w-6"
                  />
                </Link>
              </Transition>
            </div>
          ))}
        </nav>
      </div>
      <div ref={main} className="lg:col-span-4 z-[15] px-6 lg:px-0">
        {listItems.map((listItem, index) => (
          <section
            key={listItem.title}
            id={listItem.relation}
            className={`panel w-full ${`panel-${index + 1}`}`}
          >
            <div className={`w-0 h-0 ${`list-${index + 1}`}`} />
            <img
              src={`/assets/img/${listItem.relation}.png`}
              alt={`${listItem.title} descriptive`}
              className="mx-auto pt-[7rem] md:pt-0 lg:pt-20 w-[80%] lg:w-[90%]"
            />
          </section>
        ))}
      </div>
    </div>
  );
}
