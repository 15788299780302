import React from "react";
import { useParams, useHistory } from "react-router-dom";

import Loading from "../components/General/Loading";
import { useStoryblokProxy } from "../hooks/useStoryblokProxy";
import { PageBody } from "../storyblok/PageBody";
import TeamMembers from "../components/TeamMembers";
import LinkSlot from "../components/LinkSlot";
import { Helmet } from "react-helmet-async";
import { defaultPageTitle } from "../Constants";

export default function WorkDetailPage() {
  const { slug } = useParams();
  const history = useHistory();
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: slug ? `work/${slug}` : "404",
  });

  const story = !isLoading && stories.length > 0 ? stories[0] : null;

  const { isLoading: isSkillsStoriesLoading, stories: skillsStories } =
    useStoryblokProxy({
      starts_with: "categories",
      by_uuids: story?.content?.categories
        ? story?.content?.categories.join(",")
        : "",
    });
  const { isLoading: isTeamMembersLoading, stories: teamMemberStories } =
    useStoryblokProxy({
      starts_with: "team-members",
      by_uuids: story?.content?.teamMembers
        ? story?.content?.teamMembers.join(",")
        : "",
    });

  if (!isLoading && !story) {
    history.replace('/404');
    return null;
  }

  return (
    <Loading isLoading={isLoading || isSkillsStoriesLoading || isTeamMembersLoading}>
      <Helmet
        title={
          story?.content?.metatags?.title
            ? `${story?.content?.metatags?.title} - ${defaultPageTitle}`
            : defaultPageTitle
        }
        meta={[
          {
            name: "description",
            content: story?.content?.metatags?.description,
          },
        ]}
      />
      <div className="text-white">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-20 2xl:px-0 pt-20 sm:pt-40 md:pt-60 text-white relative">
          {!isSkillsStoriesLoading && (
            <ul className="md:flex mb-2 text-subtitle uppercase text-lg tracking-tight">
              {skillsStories.map((category, index) => (
                <li key={category.uuid}>
                  {category.name}
                  {index !== skillsStories.length - 1 && (
                    // eslint-disable-next-line react/jsx-no-comment-textnodes
                    <span v-if="" className="inline-block px-2">
                      //
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
          <h1 className="text-4xl md:text-5xl leading-tight md:leading-snug lg:leading-tight lg:text-7xl uppercase font-medium lg:max-w-[80%]">
            {story?.content?.title}
          </h1>
          <span className="block after:w-20 font-sm absolute uppercase text-white right-1 lg:mr-2 bottom-7 lg:bottom-10 rotate-[270deg] scroll-animation">
            Scroll
          </span>

          <div className="flex flex-col md:flex-row gap-y-10 md:gap-x-40 mt-12 mb-12 md:mb-20">
            {!isTeamMembersLoading && teamMemberStories?.length > 0 && story?.content?.teamMembers && (
              <div>
                <p className="uppercase text-lg mb-2 text-subtitle tracking-tight">
                  Team
                </p>
                <TeamMembers
                  teamMembers={teamMemberStories}
                  teamMemberIds={story?.content?.teamMembers}
                />
              </div>
            )}
            <div className="flex flex-col justify-start">
              <p className="uppercase text-lg mb-1.5 text-subtitle tracking-tight">
                Client
              </p>
              {story?.content?.clientURL?.url ? (
                <LinkSlot url={story?.content?.clientURL?.url}>
                  <h2 className="font-medium text-md md:text-2xl">  
                    {story?.content?.client}
                  </h2>
                </LinkSlot>
              ) : (
                <h2 className="font-medium text-md md:text-2xl">
                {story?.content?.client}
                </h2>
              )}
            </div>
          </div>
        </div>
        <figure className="mt-12 relative">
          <img
            src="/assets/img/video-overlay.png"
            className="absolute z-10 h-full w-full object-cover"
            alt=""
          />
          <img
            v-if="blok.hero?.filename"
            src={`${story?.content?.hero?.filename}/m/1600x0`}
            alt={story?.content?.hero?.alt}
            className="w-full max-h-[65vh] object-cover"
          />
        </figure>
        <PageBody
          className="case flex flex-col px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-lg mt-12 lg:mt-20 prose text-white"
          blok={story?.content}
        />
      </div>
    </Loading>
  );
}
