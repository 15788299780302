import React from "react";
import { Link } from "react-router-dom";

import { useStoryblokProxy } from "../../hooks/useStoryblokProxy";
import { whatWeDoHeaders } from "../../Constants";

export default function WhatWeDoOverview() {
  let categories = [];
  const { isLoading, stories } = useStoryblokProxy({
    starts_with: "what-we-do",
    is_startpage: false,
  });

  if (!isLoading) {
    const newCategories = stories.reduce((acc, cur) => {
      const category = cur.full_slug.split("/")[1];
      if (!acc[category]) {
        acc[category] = [{ name: cur.name, slug: cur.full_slug }];
      } else {
        acc[category].push({ name: cur.name, slug: cur.full_slug });
      }
      return acc;
    }, {});

    categories = newCategories;
  }

  return (
    <>
      {Object.entries(categories).map(([category, items], index) => (
        <div
          key={category}
          className="flex flex-col-reverse md:flex-row mb-20 lg:mb-32 gap-x-12 gap-y-4 items-start md:items-center justify-between"
        >
          <div className={`w-full ${index % 2 ? "md:order-1" : ""}`}>
            <h2 className="uppercase text-4xl md:text-5xl lg:text-6xl">
              {whatWeDoHeaders[category]}
            </h2>
            <ul className="pl-2 mt-8">
              {items.map((item) => (
                <li
                  key={item.name}
                  className="border-l-4 pl-4 mt-2 mb-4 md:mb-6 lg:mt-4 border-primary font-semibold text-md lg:text-2xl transform hover:translate-x-8 transition duration-500 ease-in-out"
                >
                  <Link
                    to={item.slug}
                    className="flex gap-x-4 justify-between xl:justify-normal"
                  >
                    <span className="w-full">{item.name}</span>
                    <img
                      src="assets/img/right-arrow.svg"
                      className="w-6"
                      alt=""
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <img
            src={`/assets/img/${category}.png`}
            alt={category.replace("-", " ")}
            lazy="true"
            className="w-full md:w-1/2 order-0"
          />
        </div>
      ))}
    </>
  );
}
